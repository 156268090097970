@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;
  display: block;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}
.calenderInput{
  & input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  & input[type='date'] {
    appearance: textfield;
    display: flex;
  }
  & input{
    width: 100% !important;
    padding: 12px 12px 12px 12px !important;
    display: flex;
        justify-content: flex-start;
        appearance: none; /* Removes default browser styles */
        -webkit-appearance: none;
        -moz-appearance: none;
        align-items: left;
  }
}
.defaultUserFields {
}
.errorText{
  composes: h4 from global;
  margin-top: 3px;
  margin-bottom: 0;
  color: var(--colorFail);
  overflow: hidden;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
}
.name {
  /* display: flex; */
  justify-content: space-between;
  margin-top: 16px;

  @media (--viewportMedium) {
    /* margin-top: 32px; */
  }
}
.phoneInput{
  & input {
    height: 48px !important;
    font-size: 14px !important;
    box-shadow: none !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    border-radius: 8px !important;
    background-color: #efeff4 !important;
    border: 1px solid #d8dce6 !important;
    width: 100% !important;
    &::placeholder {
      color: #8a8a8f;
    }
  }
  & :global(.flag-dropdown){
    background-color: unset !important;
    border: 1px solid rgba(128, 128, 128, 0.088) !important;
    border-radius: 10px 0 0 10px !important;
  }
}
.merchantForm {
  & .bottomWrapper {
    margin-top: 36px;
    & > div {
      padding-bottom: 36px;
      & span {
        /* font-family: SF Pro Text; */
        font-size: 16px;
        font-weight: 400;
        line-height: 22.36px;
        letter-spacing: -0.01em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
      & span[role='button'] {
        color: #3e70ca;
      }
    }
  }
  & .name {
    display: flex;
    & > div {
      width: calc(50% - 6px);
    }
  }
}
.dateOfBirth {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.business_name {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.passwordContainer {
  position: relative;
}

.row,
.customFields {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: 100%;
  /* width: calc(50% - 9px); */
}

.lastNameRoot {
  width: 100%;
  /* width: calc(50% - 9px); */
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  margin-top: 60px;
  composes: marketplaceModalBottomWrapper from global;

  & button {
    border-radius: 30px;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.48px;
    color: #fff;
    background-color: #111111;

    &:hover {
      background-color: #212020;
    }

    &:disabled {
      &:hover {
        background-color: var(--colorGrey100);
      }
    }
  }
  & label {
    gap: 10px;
  }
}

.inputBox {
  margin-bottom: 16px;
  width: 100%;
  &>div{
    width: 100%;
    &>div{
      width: 100%;
      & input{
        @media (max-width: 580px) {
          width: calc(100vw - 48px) !important;
        }
      }
    }
  }
  & input,
  & select {
    height: 48px;
    font-size: 14px;
    box-shadow: none;
    font-weight: 400;
    line-height: 22px;
    border-radius: 8px;
    background-color: #efeff4;

    &::placeholder {
      color: #8a8a8f;
    }
  }

  & label {
    display: none;
  }
}

.captchaWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}