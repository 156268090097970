@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBottomWrapperText from global;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.finePrint {
  composes: marketplaceModalHelperLink from global;
  padding: 3px 0 3px 0;
  text-align: left;
}

.privacyLink,
.termsLink {
  composes: marketplaceModalHelperLink from global;
  & span{
    font-weight: 600 !important;
  }

  &:hover {
    color: var(--marketplaceColorDark);
    text-decoration: underline;
    cursor: pointer;
  }
}
.merchantCheckBox{
  & input[type='checkbox']+label:before {
    background-image: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='16' height='15' rx='1.5' stroke='%23DCDCDD'/%3E%3C/svg%3E%0A") !important;
  }
  
  & input[type='checkbox']:checked+label:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 26 26'%3E%3Cpath fill='%2310b981' d='M17.8 10.59L12.39 16l-.53.53a.996.996 0 0 1-1.41 0l-.41-.41l-1.83-1.82a.87.87 0 0 1 0-1.23l.02-.01c.33-.34.88-.34 1.22 0l1.7 1.7l5.41-5.41c.34-.33.89-.33 1.22 0l.02.02c.33.34.33.88 0 1.22M19.99 5H6c-.55 0-1 .45-1 1.01V20c0 .55.45 1 1 1h13.99c.56 0 1.01-.45 1.01-1V6.01C21 5.45 20.55 5 19.99 5'/%3E%3C/svg%3E") !important;
    background-size: 22px;
  }
}