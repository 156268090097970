@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}
.phoneInput{
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
  & input{
    height: 48px !important;
    width: 100% !important;
    @media (max-width: 767px) {
      height: 36px !important;
    }
  }
}
.defaultUserFields {
}
.inputBoxBusiness{
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}
.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(50% - 9px);
}

.lastNameRoot {
  width: calc(50% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  & label{
    gap: 10px;
  }
}

.row,
.customFields {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}
