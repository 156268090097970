@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;
  display: block;
  /* justify-content: flex-start; */

  @media (--viewportMedium) {
    /* justify-content: space-between; */
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  margin-top: 22px;
  composes: marketplaceModalBottomWrapper from global;

  & button {
    border-radius: 30px;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.48px;
    color: #fff;
    background-color: #111111;

    &:hover {
      background-color: #212020;
    }

    &:disabled {
      &:hover {
        background-color: var(--colorGrey100);
      }
    }
  }
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  margin-top: 0;
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}

.inputBox {
  margin-bottom: 16px;

  & input {
    height: 48px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    border-radius: 8px;
    background-color: #EFEFF4;

    &::placeholder {
      color: #8A8A8F;
    }
  }

  & label {
    display: none;
  }
}